*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: 'Rubik', sans-serif;
  outline: none;
  caret-color: transparent;
}

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;0,600;1,300&display=swap');

@font-face {
  font-family: 'BasementGrotesque';
  src: local('BasementGrotesque'),
    url(./assets/fonts/BasementGrotesque-Black_v1.202.otf) format('opentype');
}

body {
  width: 100vw;
  overflow-x: hidden;
  color: #393c41;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 1rem;

    &-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background: linear-gradient(55deg, hsl(213, 100%, 90%) 0%, hsl(32, 0%, 100%) 20%);
      margin-block: 1rem;
      border-radius: 100vw;
    }

    &-thumb {
      background-color: #ff8c00;
      cursor: pointer;
      padding: 3px;
      border-radius: 100vw;
      border: 0.2rem solid orange;

      &:hover {
        background-color: #ffd439;
      }
    }
  }
}

a {
  text-decoration: none;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth !important;
}

h1 {
  font-family: 'BasementGrotesque';
  text-transform: uppercase;
}

h2 {
  font-family: 'BasementGrotesque';
  text-transform: uppercase;
}

h3 {
  font-size: 4rem;
  font-family: 'BasementGrotesque';
  text-transform: uppercase;
  display: inline-block;
  transition: all 0.2s;
  line-height: 1;
  padding: 0;
  margin: 0;
}

h4 {
  line-height: 1;
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'BasementGrotesque';
}

h5 {
  font-family: 'Rubik', sans-serif;
  line-height: 1;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
}

p {
  font-size: 1.6rem;
  line-height: 1.6;
}

h1::selection,
h2::selection,
h3::selection,
h4::selection,
h5::selection,
p::selection,
span::selection {
  background: #ff8c00;
  color: yellow;
  font-size: 1.9;
}
